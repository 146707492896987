import { motion } from "framer-motion";
import { FC, useState } from "react";

interface BandMember {
  name: string;
  role: string;
  bio: string;
  imageUrl: string;
}

const bandMembers: BandMember[] = [
  {
    name: "Mariana",
    role: "Lead Vocals & Rhythm Guitar",
    bio: "Mariana is a 14-year-old musician making an impressive mark in the music world. An exceptionally talented singer, Mariana enchants audiences with her powerful voice and emotive performances. Her musical journey is enriched by training from renowned musicians across various countries, and her creative vision is fueled by the iconic sounds of Motley Crue, Guns N' Roses, and Pink Floyd.",
    imageUrl:
      "https://createsiteai.b-cdn.net/c/_/7e0bc9f9-37f3-488f-91f7-dfe95dff5816",
  },
  {
    name: "Hunter",
    role: "Drummer",
    bio: "Hunter is a 14-year-old percussion prodigy with an unwavering passion for drumming. His journey began at the age of five when he started taking lessons at School of Rock. Now, as the drummer for the School of Rock House Band in Burbank, Hunter continues to hone his craft under the guidance of renowned teachers.",
    imageUrl:
      "https://createsiteai.b-cdn.net/c/_/ab7a3940-4078-48c9-8a68-f27179a73bb1",
  },
  {
    name: "River",
    role: "Lead Guitar",
    bio: "River is a 12-year-old guitar phenom who's been shredding since the age of 10. Inspired by the iconic moment when Angus Young jumped off an amp during an AC/DC performance, River picked up her first guitar and never looked back. Since then, she's dedicated herself to mastering the instrument.",
    imageUrl:
      "https://createsiteai.b-cdn.net/c/_/e3abec7a-9d9f-4bd1-94e9-c38612cddb57",
  },
  {
    name: "Kiran",
    role: "Bassist",
    bio: "Kiran, 11, is the dynamic bassist of The Wendolls rock band. With a passion for music that knows no bounds, he's been playing the bass guitar and electric guitar for four years, studying under Pierre Karlsson and honing his skills and developing his unique style.",
    imageUrl:
      "https://createsiteai.b-cdn.net/c/_/1a8316d1-d1c3-4d20-ba7c-109d1fe406c4",
  },
];

const MemberCard: FC<BandMember> = ({ name, role, bio, imageUrl }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <motion.div
      className="relative desktop:h-[500px] laptop:h-[500px] tablet:h-[500px] mobile:h-auto w-full perspective-1000"
      onHoverStart={() => setIsFlipped(true)}
      onHoverEnd={() => setIsFlipped(false)}
    >
      <motion.div
        className="w-full desktop:h-full laptop:h-full tablet:h-full mobile:flex mobile:flex-col"
        animate={{ rotateY: isFlipped ? 180 : 0 }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
        style={{ transformStyle: "preserve-3d" }}
      >
        {/* Front of card */}
        <div
          className="desktop:absolute laptop:absolute tablet:absolute mobile:relative inset-0 w-full desktop:h-full laptop:h-full tablet:h-full mobile:h-[300px] backface-hidden"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
          <div className="absolute bottom-0 left-0 p-6 text-white">
            <h3 className="text-2xl font-bold">{name}</h3>
            <p className="text-white/70">{role}</p>
          </div>
        </div>

        {/* Back of card */}
        <div
          className="desktop:absolute laptop:absolute tablet:absolute mobile:relative inset-0 w-full desktop:h-full laptop:h-full tablet:h-full mobile:h-auto bg-black/95 p-6 text-white backface-hidden desktop:block laptop:block tablet:block mobile:hidden"
          style={{ transform: "rotateY(180deg)" }}
        >
          <div className="h-full flex flex-col justify-center">
            <h3 className="text-2xl font-bold mb-4">{name}</h3>
            <p className="text-white/70 leading-relaxed">{bio}</p>
          </div>
        </div>
      </motion.div>
      {/* Mobile-only description */}
      <div className="w-full bg-black/95 p-6 text-white desktop:hidden laptop:hidden tablet:hidden mobile:block">
        <div className="flex flex-col">
          <h3 className="text-2xl font-bold mb-4">{name}</h3>
          <p className="text-white/70 leading-relaxed">{bio}</p>
        </div>
      </div>
    </motion.div>
  );
};

const MeetTheBand: FC = () => {
  return (
    <section className="bg-black text-white py-20">
      <div className="desktop:px-40 laptop:px-32 tablet:px-16 mobile:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8"
        >
          {bandMembers.map((member) => (
            <MemberCard key={member.name} {...member} />
          ))}
        </motion.div>
      </div>

      <style>{`
        .perspective-1000 {
          perspective: 1000px;
        }
        .backface-hidden {
          backface-visibility: hidden;
        }
      `}</style>
    </section>
  );
};

export default MeetTheBand;
