import React from "react";
import Hero from "../components/Hero";
import MeetTheBand from "../components/MeetTheBand";
import FullWidthImage from "../components/FullWidthImage";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <MeetTheBand />
      <FullWidthImage />
    </>
  );
};

export default Home;
