import { motion } from "framer-motion";
import { FC } from "react";

const Hero: FC = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const wordAnimation = {
    hidden: { y: 200, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 16,
        stiffness: 120,
      },
    },
  };

  const lineAnimation = {
    hidden: { scaleX: 0 },
    visible: {
      scaleX: 1,
      transition: {
        duration: 1.2,
        ease: "easeInOut",
      },
    },
  };

  return (
    <section className="min-h-screen bg-black text-white overflow-hidden relative flex items-center">
      {/* Noise texture overlay */}
      <div className="absolute inset-0 opacity-30 noise-texture" />

      {/* Gradient vignette */}
      <div className="absolute inset-0 bg-gradient-radial" />

      {/* Vertical decorative lines */}
      <motion.div
        className="absolute left-8 top-0 h-full w-[1px] bg-white/5"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        transition={{ duration: 1.5, delay: 0.2 }}
      />
      <motion.div
        className="absolute right-8 top-0 h-full w-[1px] bg-white/5"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        transition={{ duration: 1.5, delay: 0.4 }}
      />

      <div className="relative w-full desktop:px-40 laptop:px-32 tablet:px-16 mobile:px-8 desktop:py-40 laptop:py-32 tablet:py-24 mobile:py-20">
        {/* Top metadata */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.8 }}
          className="flex items-center gap-6"
        >
          <motion.div
            variants={lineAnimation}
            className="h-[1px] w-12 bg-white/20 origin-left"
          />
          <span className="font-light tracking-[0.6em] desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs uppercase text-white/50">
            Los Angeles
          </span>
        </motion.div>

        <div className="desktop:mt-24 laptop:mt-20 tablet:mt-16 mobile:mt-12 grid desktop:grid-cols-[1.5fr,1fr] laptop:grid-cols-[1.5fr,1fr] tablet:grid-cols-1 mobile:grid-cols-1 gap-20">
          <div>
            {/* Main title animation */}
            <motion.div
              className="overflow-hidden flex"
              variants={staggerChildren}
              initial="hidden"
              animate="visible"
            >
              <motion.span
                variants={wordAnimation}
                className="desktop:text-[13rem] laptop:text-[10rem] tablet:text-[8rem] mobile:text-[3.5rem] font-black leading-[0.75] tracking-tighter desktop:mr-8 laptop:mr-8 tablet:mr-8 mobile:mr-4"
              >
                The
              </motion.span>
              <motion.span
                variants={wordAnimation}
                className="desktop:text-[13rem] laptop:text-[10rem] tablet:text-[8rem] mobile:text-[3.5rem] font-black leading-[0.75] tracking-tighter"
              >
                Wendolls
              </motion.span>
            </motion.div>

            {/* Description */}
            <motion.div
              className="desktop:mt-24 laptop:mt-20 tablet:mt-16 mobile:mt-12 desktop:max-w-2xl laptop:max-w-xl"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 1, delay: 1 }}
            >
              <p className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-sm font-light leading-relaxed text-white/70">
                Get ready to experience the explosive energy of The Wendolls, a
                young rock band born from the passion and creativity of four
                talented teens. With powerful vocals, pulsating drums, scorching
                guitar riffs, and thundering bass lines, this quartet is
                unstoppable. Follow{" "}
                <a
                  href="https://linktr.ee/thewendolls"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/90 hover:text-white transition-colors duration-300"
                >
                  https://linktr.ee/thewendolls
                </a>
                <br />
                Contact{" "}
                <a
                  href="mailto:thewendollsofficial@gmail.com"
                  className="text-white/90 hover:text-white transition-colors duration-300"
                >
                  thewendollsofficial@gmail.com
                </a>
              </p>
            </motion.div>
          </div>

          {/* Right column */}
          <motion.div
            className="desktop:self-end laptop:self-end tablet:self-start mobile:self-start"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.8, delay: 1.2 }}
          >
            <div className="space-y-12">
              <button
                className="
                group
                w-full
                desktop:text-lg laptop:text-base tablet:text-base mobile:text-sm
                border border-white/10
                desktop:px-12 laptop:px-10 tablet:px-8 mobile:px-6
                desktop:py-8 laptop:py-6 tablet:py-5 mobile:py-4
                hover:border-white/40 transition-all duration-700
                font-light tracking-[0.4em] uppercase
                relative overflow-hidden
              "
              >
                <span className="relative z-10 group-hover:text-black transition-colors duration-700">
                  Meet The Band
                </span>
                <div className="absolute inset-0 bg-white transform -skew-x-12 scale-x-0 group-hover:scale-x-100 transition-transform duration-700 origin-left" />
              </button>

              <div className="space-y-6">
                <motion.div
                  variants={lineAnimation}
                  className="h-[1px] w-full bg-white/10 origin-left"
                />
                <div className="space-y-2">
                  <p className="font-light text-xs uppercase tracking-[0.4em] text-white/30">
                    Scroll to discover
                  </p>
                  <p className="font-light text-xs uppercase tracking-[0.4em] text-white/50">
                    Their Story
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <style>{`
        .noise-texture {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.85' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
          filter: contrast(400%) brightness(70%);
        }

        .bg-gradient-radial {
          background: radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.8) 100%);
        }

        @keyframes subtleFloat {
          0% { transform: translate(0, 0) }
          50% { transform: translate(-1px, -1px) }
          100% { transform: translate(0, 0) }
        }

        .noise-texture {
          animation: subtleFloat 8s ease infinite;
        }

        section {
          background: linear-gradient(150deg, #000000, #080808);
        }
      `}</style>
    </section>
  );
};

export default Hero;
