import { motion } from "framer-motion";
import { FC } from "react";

const FullWidthImage: FC = () => {
  return (
    <section className="bg-black text-white relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true }}
        className="desktop:px-40 laptop:px-32 tablet:px-16 mobile:px-8 desktop:py-40 laptop:py-32 tablet:py-24 mobile:py-20 relative"
      >
        <div className="relative">
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60 z-10" />

          {/* Image */}
          <img
            src="https://createsiteai.b-cdn.net/c/_/331d1b2b-4563-4fee-96f0-d1413b2127f7"
            alt="The Wendolls"
            className="w-full h-auto object-cover"
          />
        </div>
      </motion.div>
    </section>
  );
};

export default FullWidthImage;
